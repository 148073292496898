import React, { useState } from 'react';
import './navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  window.addEventListener('resize', () => {
    document.querySelector('nav').classList.add('no-transition');
    setTimeout(() => { document.querySelector('nav').classList.remove('no-transition');}, 500);
  })


  const toggleNav = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      var navTarget = document.getElementById('headerNav');
      navTarget.scrollIntoView({ behavior: 'smooth' });
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const closeNav = () => {
    setMenuOpen(false);
    document.body.classList.remove('no-scroll');
  };

  return (
    <>
      <div id='nav-focus' className={menuOpen ? 'nav__opacity' : ''}></div>
      <div className='menu-btn' onClick={toggleNav}>
        <span></span>
      </div>
      <nav id='headerNav' className={menuOpen ? 'nav-active' : ''}>
        <ul className='text-nav' onClick={closeNav}>
          <li><a href="#pagina-inicial" rel="noopener noreferrer">Página Inicial</a></li>
          <li><a href="#area-de-atuacao" rel="noopener noreferrer">Áreas de atuação</a></li>
          <li><a href="#nossa-equipe" rel="noopener noreferrer">Nossa equipe</a></li>
          <li><a href="#contato" rel="noopener noreferrer">Contato</a></li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
