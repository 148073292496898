import React from 'react';
import './hero.css';
import { ButtonWp } from '../../components';

const Hero = () => {
  return (
    <section className='hero full-width text-light flex' id='pagina-inicial'> 
        <div className="hero__content full-width content-grid">
        <h1>LEMES ADVOCACIA</h1>
        <p className='text-align-left'>Experiência. Atendimento Personalizado. Excelência.</p>
        <div className="hero__content_buttonWp flex">
        <ButtonWp />
        </div>
        <p>Com experiência de mais de 15 anos atuando no Estado de São Paulo, com destaque para o Vale do Paraíba, o escritório preza por uma atuação ética e personalizada. Cada caso é tratado como único, acompanhado com rigor técnico de alto nível.</p>
        </div>
    </section>
    
  )
}

export default Hero;
