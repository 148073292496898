import React from 'react';
import './descriptionCard.css';

const DescriptionCard = ({image, alt, title, text}) => {
  return (
    <div className='descriptionCard grid'>
      <img src={image} alt={alt} />
      <div className="descriptionCard__content">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default DescriptionCard;
