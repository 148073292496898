import React from 'react';
import './footer.css';
import { ButtonWp } from '../../components';

let date = new Date();
let year = date.getFullYear();

const Footer = () => {
  return (
    <footer className='bg-light full-width content-grid' id='contato'>
      <h2 className='text-accent'>CONTATO</h2>
      <p>(12) 98294-0437 (Dr. Airton Lemes)</p>
      <p>(12) 98160-5214 (Dra. Karoline Lessa)</p>
      <div className="social-links flex">
      <a href="mailto:lemesadvocacia@gmail.com" target='_blank' rel="noopener noreferrer"><ion-icon name="mail-outline"></ion-icon></a>
      <a href='https://www.facebook.com/www.lemesadvogadosassociados.com.br/' target='_blank' rel="noopener noreferrer"><ion-icon name="logo-facebook"></ion-icon></a>
      <a href='https://www.instagram.com/lemes_advogados/' target='_blank' rel="noopener noreferrer"><ion-icon name="logo-instagram"></ion-icon></a>
      </div>
      <ButtonWp />
      <p>Lemes Advocacia &copy;{year}</p>
    </footer>
  )
}

export default Footer;
