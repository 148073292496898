
import './App.css';
import { ArrowUp} from './components';
import {Header, Hero, OccupationArea, Team, Footer} from './containers'

function App() {
  return (
    <>
      <div className="App">
        <Header/>
        <main className="content-grid">
        <Hero/>
        <OccupationArea/>
        <Team/>
        </main>
        <Footer/>
        <ArrowUp />
      </div>
    </>
  );
}

export default App;
