import React, { useState, useEffect, useRef } from 'react';
import './arrowUp.css';

const ArrowUp = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [clicked, setClicked] = useState(false);
  const arrowRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const target = document.getElementById('pagina-inicial'); 
    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, []);

  const handleClick = () => {
    const target = document.getElementById('topo');
    target.scrollIntoView({ behavior: 'smooth' });
    setClicked(true)
    setTimeout(() => {setClicked(false)}, 300)
  };

  return (
    <div
      ref={arrowRef}
      onClick={handleClick}
      id='arrow-up'
      style={{ display: isVisible ? 'none' : 'flex' }}
      className={clicked ? 'arrowClicked' : ''}
    >
      <ion-icon name="chevron-up-outline"></ion-icon>
    </div>
  );
};

export default ArrowUp;
