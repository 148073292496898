import React from 'react';
import './buttonWp.css';

const Button = () => {
  return (
    <div id='buttonWp' className='animation full-width'>
      <a href='https://api.whatsapp.com/send/?phone=5512982940437&text=Estou+entrando+em+contato+atrav%C3%A9s+do+site%21+Gostaria+de+agendar+uma+consulta.&type=phone_number&app_absent=0' target='_blank' className='text-light bg-green' rel="noopener noreferrer">Entre em Contato <ion-icon name="logo-whatsapp"></ion-icon></a>
    </div>
  )
}

export default Button;
