import React from 'react';
import './team.css';
import PersonalCard from '../../components/personalCard/PersonalCard';

const Team = () => {
  return (
    <section className='team section-padding bg-accent full-width content-grid' id='nossa-equipe'>
      <h2 className='text-accent'>NOSSA EQUIPE</h2>
      <div className="bar"></div>
      <div className="team__personalCards section-padding flex">
      <PersonalCard occupation='Advogado' fullName='Airton Lemes' formationUniv='Universidade Paulista (UNIP) - 2009' document='OAB - 303.315'/>
      <PersonalCard occupation='Advogada' fullName='Karoline Lessa' formationUniv='Universidade de São Paulo (USP) - 2018' document='OAB - 428.429'/>
    </div>
    </section>
  )
}

export default Team;
