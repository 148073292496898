import React from 'react';
import './occupationArea.css';
import {ButtonWp, DescriptionCard} from '../../components';
import {civil, criminal, familia, saude, consumidor, sucessoes} from '../../assets';

const OccupationArea = () => {
  return (
    <section className='occupationArea section-padding bg-light full-width content-grid' id='area-de-atuacao'>
      <h2 className='text-accent'>ÁREAS DE ATUAÇÃO</h2>
      <div className="section-padding occupation-grid grid">
      <DescriptionCard image={sucessoes} title='Direito das Sucessões' text='Atuação judicial e extrajudicial na sucessão do patrimônio deixado pelo de cujus, bem como proteção do patrimônio familiar por meio do planejamento sucessório.'/>
      <DescriptionCard image={civil} title='Direito Civil' text='Atuação no âmbito contencioso (ações judiciais) e consultivo (elaboração/revisão de contratos, acordos, entre outros).'/>
      <DescriptionCard image={consumidor} title='Direito do Consumidor' text='Atuação no âmbito judicial e administrativo, visando a orientar e garantir a efetividade do direito dos consumidores.'/>
      <DescriptionCard image={familia} title='Direito de Família' text='Atuação judicial e extrajudicial especializada, visando a melhor resolução do conflito para todos os envolvidos.'/>
      <DescriptionCard image={saude} title='Direito da Saúde' text='Atuação judicial e extrajudicial na defesa dos interesses de pessoas físicas junto aos planos de saúde.'/>
      <DescriptionCard image={criminal} title='Direito Criminal' text='Atuação estratégica, de maneira consultiva, preventiva e contenciosa, em defesa do acusado ou a favor da vítima.'/>
      </div>
      <ButtonWp />
    </section>
  )
}

export default OccupationArea;
