import React from 'react';
import './personalCard.css';

const PersonalCard = ({occupation, fullName, formationUniv, document}) => {

  return (
    <div className='personalCard bg-light'>
      <p>{occupation}</p>
      <h3>{fullName}</h3>
      <p>{formationUniv}</p>
      <p>{document}</p>
    </div>
  )
}

export default PersonalCard;
