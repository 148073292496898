import React from 'react';
import './header.css';
import {logo} from '../../assets'
import {Navbar} from '../../components';

const Header = () => {
  return (
    <header className='bg-light' id='topo'>
      <div className="header__logo">
        <a href="#pagina-inicial"><img src={logo} alt="logo" /></a>
      </div>
      <Navbar />
    </header>
  )
}

export default Header;
